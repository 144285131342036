import React from "react";
import "../../../style/LatestUpdate.css";
// import Victoryday from "../../../images/sixteen-december.JPG";
// import augustImage from "../../../images/happy-new-year.jpg";
// import CoordinationMeeting from "../../../images/Blogs-photo/03-01-2024/IMG_9167.JPG";
// import ExchangeMeeting from "../../../images/Blogs-photo/07.02.2024/exchange-meeting.jpeg";
// import MotherLanguageDay from "../../../images/Blogs-photo/21.02.2024/21st-february.JPG";
// import MonthlyCoordinationMeeting from "../../../images/Blogs-photo/22.02.2024/1.jpeg";
// import MonitoringOne from "../../../images/Blogs-photo/11.03.2024/Cover-image.JPG";
// import BongobondhuBirthday from "../../../images/Blogs-photo/17.03.2024/IMG_9430.JPG";
// import TwentySixthMarch from "../../../images/Blogs-photo/26.03.2024/3.JPG";
import FloodInRangamati from "../../../images/Blogs-photo/22.08.2024/10.jpeg";
import AidDistribution from "../../../images/Blogs-photo/27.03.2024/Cover-Photo.jpg";
import LiteracyDay from "../../../images/Blogs-photo/08.09.2024/Five.jpg";

const LatestUpdate = () => {
  return (
    <div className="latest_section">
      <div className="container">
        <h2>Latest Updates</h2>
        <div className="latest_image_wrapper">

          <div className="single_wrapper">
            <div className="image_wrapper">
              <a
                href="/international-literacy-day-2024"
                target={"_blank"}
              >
                <img src={LiteracyDay} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>September 08, 2024</p>
              <a
                href="/international-literacy-day-2024"
                target={"_blank"}
              >
                Colorful rally to celebrate International Literacy Day 2024
              </a>
            </div>
          </div>

          <div className="single_wrapper">
            <div className="image_wrapper">
              <a
                href="/Humanitarian-Aid-Distribution-to-the-flood-affected-families"
                target={"_blank"}
              >
                <img src={AidDistribution} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>August 26, 2024</p>
              <a
                href="/Humanitarian-Aid-Distribution-to-the-flood-affected-families"
                target={"_blank"}
              >
                Humanitarian Aid Distribution to the flood affected families at Toi-Chakma Dosarpara in Naniarchar Upazila of Rangamati District
              </a>
            </div>
          </div>

          <div className="single_wrapper">
            <div className="image_wrapper">
              <a
                href="/Flash-flood-and-landslide-in-rangamati"
                target={"_blank"}
              >
                <img src={FloodInRangamati} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>August 21, 2024</p>
              <a
                href="/Flash-flood-and-landslide-in-rangamati"
                target={"_blank"}
              >
                Recent Flash Flood and landslide in Rangamati
              </a>
            </div>
          </div>

          {/* <div className="single_wrapper">
            <div className="image_wrapper">
              <a
                href="/Ashroy-Angon-Society-has-celebrated-National-Independence-Day"
                target={"_blank"}
              >
                <img src={TwentySixthMarch} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>March 26, 2024</p>
              <a
                href="/Ashroy-Angon-Society-has-celebrated-National-Independence-Day"
                target={"_blank"}
              >
                Ashroy Angon Society has celebrated National Independence Day
                with respect in Sadar and the other six upazilas
              </a>
            </div>
          </div> */}

          {/* <div className="single_wrapper">
            <div className="image_wrapper">
              <a
                href="/104th-birthday-of-bongobondhu-sheikh-mujibur-rahman"
                target={"_blank"}
              >
                <img src={BongobondhuBirthday} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>March 17, 2024</p>
              <a
                href="/104th-birthday-of-bongobondhu-sheikh-mujibur-rahman"
                target={"_blank"}
              >
                Celebrating the 104th Birthday of Jatir Jonok Bongobondhu Sheikh
                Mujibur Rahman on 17 th March, 2024
              </a>
            </div>
          </div> */}

          {/* <div className="single_wrapper">
            <div className="image_wrapper">
              <a
                href="/visit-shikon-kendros-of-ashroy-angon-society"
                target={"_blank"}
              >
                <img src={MonitoringOne} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>March 11, 2024</p>
              <a
                href="/visit-shikon-kendros-of-ashroy-angon-society"
                target={"_blank"}
              >
                Upazila Nirbahi Officer, Rangamati Sadar Mr. Mostafa Jabed
                Kaychar's Visit to Sikhon Kendras of Ashroy Angon Society
              </a>
            </div>
          </div> */}

          {/* <div className="single_wrapper">
            <div className="image_wrapper">
              <a href="/monthly-ngo-coordination-meeting" target={"_blank"}>
                <img src={MonthlyCoordinationMeeting} alt="" />
              </a>
            </div>
            <div className="image_title">
              <p>February 22, 2024</p>
              <a href="/monthly-ngo-coordination-meeting" target={"_blank"}>
                Monthly NGO Coordination Meeting Promotes Transparency and
                Collaboration
              </a>
            </div>
          </div> */}

          {/* <Zoom duration={2000}>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/international-mother-language-day" target={"_blank"}>
                  <img src={MotherLanguageDay} alt="" />
                </a>
              </div>
              <div className="image_title">
                <p>February 21, 2024</p>
                <a href="/international-mother-language-day" target={"_blank"}>
                  Ashroy Angon Society Celebrates International Mother Language
                  Day Across Six Upazillas
                </a>
              </div>
            </div>
          </Zoom> */}

          {/* <Zoom duration={2000}>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/exchange-meeting" target={"_blank"}>
                  <img src={ExchangeMeeting} alt="" />
                </a>
              </div>
              <div className="image_title">
                <p>February 7, 2024</p>
                <a href="/exchange-meeting" target={"_blank"}>
                  An exchange meeting of staffs of "Out of School Children
                  Education Program", Betbunia and Fatikchari
                </a>
              </div>
            </div>
          </Zoom> */}

          {/* <Zoom duration={2000}>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a
                  href="/coordination-meeting-with-upazila-project-managers"
                  target={"_blank"}
                >
                  <img src={CoordinationMeeting} alt="" />
                </a>
              </div>
              <div className="image_title">
                <p>February 3, 2024</p>
                <a
                  href="/coordination-meeting-with-upazila-project-managers"
                  target={"_blank"}
                >
                  The Out of School Children Education Program held a crucial
                  coordination meeting at the head office
                </a>
              </div>
            </div>
          </Zoom> */}

          {/* <Zoom duration={2000}>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/happy-new-year" target={"_blank"}>
                  <img src={augustImage} alt="" />
                </a>
              </div>
              <div className="image_title">
                <p>January 1, 2024</p>
                <a href="/happy-new-year" target={"_blank"}>
                  "Embracing Joy and Hope: Welcoming the Arrival of Happy New
                  Year 2024" On behalf of Ashroy Angon Society
                </a>
              </div>
            </div>
          </Zoom> */}

          {/* <Zoom duration={2000}>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/sixteen-december" target={"_blank"}>
                  <img src={Victoryday} alt="" />
                </a>
              </div>
              <div className="image_title">
                <p>December 16, 2023</p>
                <a href="/sixteen-december" target={"_blank"}>
                  "Bangladesh Triumphs: Victory Day Celebrations Commemorate
                  Freedom and Resilience"
                </a>
              </div>
            </div>
          </Zoom> */}

          {/* <Zoom duration={2000}>
            <div className="single_wrapper">
              <div className="image_wrapper">
                <a href="/fifteen-august-news" target={"_blank"}>
                  <img src={fifteenAugustImage} alt="" />
                </a>
              </div>
              <div className="image_title">
                <p>AUGUST 15, 2023</p>
                <a href="/fifteen-august-news" target={"_blank"}>
                  15th August National Mourning Day 2023 Asharya Angan Society
                  tribute to the father of the nation
                </a>
              </div>
            </div>
          </Zoom> */}
        </div>
        {/* <div className="border"></div> */}
      </div>
    </div>
  );
};

export default LatestUpdate;
