import React, { useEffect } from "react";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import TranslateButton from "../../TranslateButton/TranslateButton";
import One from "../../../images/Profile-Md-Monirul-Hasan/1.jpg";
import Two from "../../../images/Profile-Md-Monirul-Hasan/2.jpg";
import Three from "../../../images/Profile-Md-Monirul-Hasan/3.jpg";
import Four from "../../../images/Profile-Md-Monirul-Hasan/4.jpg";
import Five from "../../../images/Profile-Md-Monirul-Hasan/5.jpg";
import Six from "../../../images/Profile-Md-Monirul-Hasan/6.jpg";
import Seven from "../../../images/Profile-Md-Monirul-Hasan/7.jpg";
import Eight from "../../../images/Profile-Md-Monirul-Hasan/8.jpg";
import Nine from "../../../images/Profile-Md-Monirul-Hasan/9.jpg";
import Ten from "../../../images/Profile-Md-Monirul-Hasan/10.jpg";
import "../../../style/profile/monirulHasan.css";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

const MonirulHasan = () => {
  useEffect(() => {
    document.title = `AshroyAngon | Md. Monirul Hasan Cv`;
  }, []);

  const shareUrl = "https://ashroyangon.org/md-monirul-hasan-cv";

  return (
    <>
      <Navbar />
      <TranslateButton />

      <div className="monirul_hasan_section">
        {/* Header Background Part Start */}
        <div className="header_background">
          <div className="container wrapper">
            <h2>Profile of Md. Monirul Hasan</h2>
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Header Background Part End */}

        <div className="monirul_hasan_wrapper">
          <div className="container">
            <h3>Profile of Md. Monirul Hasan</h3>
            <div className="content_wrapper">
              <img src={One} alt="" />
              <img src={Two} alt="" />
              <img src={Three} alt="" />
              <img src={Four} alt="" />
              <img src={Five} alt="" />
              <img src={Six} alt="" />
              <img src={Seven} alt="" />
              <img src={Eight} alt="" />
              <img src={Nine} alt="" />
              <img src={Ten} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MonirulHasan;
