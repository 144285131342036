import React, { useEffect } from "react";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import "../../../style/about/policy.css";
import TranslateButton from "../../TranslateButton/TranslateButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarespace } from "@fortawesome/free-brands-svg-icons";

const Policy = () => {
  useEffect(() => {
    document.title = `AshroyAngon | Core Policy`;
  }, []);

  const shareUrl = "https://ashroyangon.org/about/core-policy";

  return (
    <>
      <Navbar />
      <TranslateButton />

      <div className="policy_section">
        {/* Header Background Part Start */}
        <div className="header_background">
          <div className="container wrapper">
            <h2>Core Policy</h2>
            <div className="social_share_button">
              <FacebookShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share facebook"}
                hashtag={"#facebook"}
              >
                <FacebookIcon size={35} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share twitter"}
                hashtag={"#twitter"}
              >
                <TwitterIcon size={35} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                target={"_blank"}
                quote={"share linkedin"}
                hashtag={"#linkedin"}
              >
                <LinkedinIcon size={35} round={true} />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
        {/* Header Background Part End */}

        <div className="policy_wrapper">
          <h3>Ashroy Angon Society Core policies</h3>
          <div className="container">
            <div className="content_wrapper">
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Human Resource Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Financial Management Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Gender Policy</p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Child Safe Guarding Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Anti- Corruption Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Protection Form Sexual Exploitation and Abuse (PSEA) </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Vehicle Use and Management Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Whistle Blowing Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Safe Guarding Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Do No Harm (DNH) Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Information Disclosure Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Anti-Money Laundering Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Risk Management Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Anti-Fraud & Dishonesty, Bribery and Corruption Policy </p>
              </div>
              <div className="icon_and_text">
                <FontAwesomeIcon icon={faSquarespace} />
                <p>Anti-Terrorism Policy </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policy;
