import React, { useEffect } from "react";
import Footer from "../../shared/Footer/Footer";
import Navbar from "../../shared/Navbar/Navbar";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import "../../../style/about/ashroyAngonProfile.css";
import Fade from "react-reveal/Fade";
import TranslateButton from "../../TranslateButton/TranslateButton";
import One from "../../../images/Profile/1.jpg";
import Two from "../../../images/Profile/2.jpg";
import Three from "../../../images/Profile/3.jpg";
import Four from "../../../images/Profile/4.jpg";
import Five from "../../../images/Profile/5.jpg";
import Six from "../../../images/Profile/6.jpg";
import Seven from "../../../images/Profile/7.jpg";

const AshroyAngonProfile = () => {
  useEffect(() => {
    document.title = `AshroyAngon | Ashroy Angon Profile`;
  }, []);

  const shareUrl = "https://ashroyangon.org/about/ashroy-angon-profile";

  return (
    <>
      <Navbar />
      <TranslateButton />

      <div className="profile_section">
        <div className="header_image"></div>
        <div className="container">
          {/* Social Share Section Start */}
          <div className="social_share_button">
            <FacebookShareButton
              url={shareUrl}
              target={"_blank"}
              quote={"share facebook"}
              hashtag={"#facebook"}
            >
              <FacebookIcon size={35} round={true} />
            </FacebookShareButton>
            <TwitterShareButton
              url={shareUrl}
              target={"_blank"}
              quote={"share twitter"}
              hashtag={"#twitter"}
            >
              <TwitterIcon size={35} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton
              url={shareUrl}
              target={"_blank"}
              quote={"share linkedin"}
              hashtag={"#linkedin"}
            >
              <LinkedinIcon size={35} round={true} />
            </LinkedinShareButton>
          </div>
          {/* Social Share Section End */}
          <div>
            <h2>Ashroy Angon Profile</h2>
          </div>

          <div className="content_wrapper">
            <img src={One} alt="" />
            <img src={Two} alt="" />
            <img src={Three} alt="" />
            <img src={Four} alt="" />
            <img src={Five} alt="" />
            <img src={Six} alt="" />
            <img src={Seven} alt="" />
          </div>

          <div className="content_wrapper">
            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h3>Year</h3>
                </div>
                <div className="right_content">
                  <h3>Title of Projects</h3>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2021</h4>
                </div>
                <div className="right_content">
                  <h4>Ensure Benefit of E-Commerce to Women Entrepreneurs</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Information &amp; Communication
                    Technology
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 100 Women Entrepreneurs
                  </p>
                  <p>
                    <span>Duration:</span> June 2021- June 2025
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rangamati Sadar, Rangamamti
                    Hill District.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2021</h4>
                </div>
                <div className="right_content">
                  <h4>Out of School Children Education Program</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Bureau of Non-Formal Education
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 12,600 Students
                  </p>
                  <p>
                    <span>Duration:</span> June 2021- June 2025;
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajostholi, Kaptai,
                    Belaichari, Kawkhali, Jurachari and Sadar of Rangamati Hill
                    District.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2018</h4>
                </div>
                <div className="right_content">
                  <h4>Basic Literacy Project</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Bureau of Non-Formal Education, Ministry
                    of Primary &amp; Mass education, Bangladesh
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 11,700 Students
                  </p>
                  <p>
                    <span>Duration:</span> 2018 to 2022
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajasthali Upazila,
                    Rangamati Hill District.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2017</h4>
                </div>
                <div className="right_content">
                  <h4>
                    Income Generating activities through Beautification &amp;
                    Sewing Training Center
                  </h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Department of Social Service, Rangamati
                    and Ashroy Angon
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 520 Female
                  </p>
                  <p>
                    <span>Duration:</span> Since 2017 to continue
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajasthali and Sadar
                    Upazila, Rangamati Hill District.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2017</h4>
                </div>
                <div className="right_content">
                  <h4>Primary Health Care project in Remote Area (PHCPRA)</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Ministry of Health &amp; Family Planning
                    Department, Bangladesh and Ashroy Angon Society Initiatives
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 5000 (Male-2000: Female-3000)
                  </p>
                  <p>
                    <span>Duration:</span> Since 2017 to continue
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rangamati Sadar and Kaptai
                    Upazila, Rangamati Hill District.
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2005</h4>
                </div>
                <div className="right_content">
                  <h4>
                    Community Managed Water Supply and Sanitation Program
                    (CMWSP) for the Rural Poor of the Chittagong Hill Tracts:
                  </h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> NGO Forum for Drinking Water Supply and
                    Sanitation
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 286 HHs in 6 Villages
                  </p>
                  <p>
                    <span>Duration:</span> 3 years (2005-2007)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Ghilachari Union, Rajostholi
                    Upazila, Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2009</h4>
                </div>
                <div className="right_content">
                  <h4>
                    Community Managed Water Supply and Sanitation Program
                    (CMWSP) for the Rural Poor of the Chittagong Hill Tracts
                  </h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> NGO Forum for Drinking Water Supply and
                    Sanitation
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 1000 HHs in 34 Villages
                  </p>
                  <p>
                    <span>Duration:</span> 4 years (2009-2012)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajostholi Upazila,
                    Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2005</h4>
                </div>
                <div className="right_content">
                  <h4>Community Empowerment Project</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> UNDP-CHTDF
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 110 Village Development
                    Committees and 6 Women Development Committees
                  </p>
                  <p>
                    <span>Duration:</span> 4 years (2005-2008)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajostholi Upazila,
                    Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2006</h4>
                </div>
                <div className="right_content">
                  <h4>Homestead Food Production Program</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Hellen Keller International (HKI)
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 1025 HHs
                  </p>
                  <p>
                    <span>Duration:</span> 3 years (2006-2008)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Bangalhalia and Ghinda
                    Union, Rajostholi Upazila, Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2006</h4>
                </div>
                <div className="right_content">
                  <h4>Non-Formal Primary Education (1 st Phase)</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> BRAC
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 150 Students (Boys-75:Girls-75)
                    in 5 Schools
                  </p>
                  <p>
                    <span>Duration:</span> 3 years (2006-2008)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajostholi Upazila,
                    Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2009</h4>
                </div>
                <div className="right_content">
                  <h4>Non-Formal Primary Education:(2 nd Phase)</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> BRAC
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 250 Students
                    (Boys-150:Girls-100) in 10 Schools
                  </p>
                  <p>
                    <span>Duration:</span> 3 years (2009-2011)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Kaptai Upazila, Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2009</h4>
                </div>
                <div className="right_content">
                  <h4>Non-Formal Primary Education ( 3rd Phase)</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> BRAC
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 125 Students (Boys-75:Girls-50)
                    in 10 Schools
                  </p>
                  <p>
                    <span>Duration:</span> 3 years (2009-2011)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Kaptai Upazila, Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2010</h4>
                </div>
                <div className="right_content">
                  <h4>CHT-HYSAWA Fund</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> GOB-DANIDA
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 1837 in 218 HH in 10 villages;
                  </p>
                  <p>
                    <span>Duration:</span> 2 Years (2010-2011)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Kaptai Upazila, Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2013</h4>
                </div>
                <div className="right_content">
                  <h4>CHT-HYSAWA Fund</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> GOB-DANIDA &amp; Union Porishad
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 5300 in 28 villages
                  </p>
                  <p>
                    <span>Duration:</span> 2 Years (2013-2015)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Sadar and Jurachari Upazila,
                    Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2010</h4>
                </div>
                <div className="right_content">
                  <h4>Support to Basic Education in the CHT</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> UNDP-CHTDF
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 2750 students(Boys: 1370: Girls:
                    1380)
                  </p>
                  <p>
                    <span>Duration:</span> 3 Years (2010-2013)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Jurachari Upazila,Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2013</h4>
                </div>
                <div className="right_content">
                  <h4>
                    Integrated Support to Promote Primary Education in Remote
                    Areas in CHT
                  </h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> Manusher Jonno Foundation
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 113 Students (Boys- 57: Girls-
                    56)
                  </p>
                  <p>
                    <span>Duration:</span> 5 Years (2013- 2017)
                  </p>
                  <p>
                    <span>Implementing Area:</span> Rajostholi Upazila,
                    Rangamati
                  </p>
                </div>
              </div>
            </Fade>

            <Fade bottom duration={2000}>
              <div className="single_content_wrapper">
                <div className="left_content">
                  <h4>2020</h4>
                </div>
                <div className="right_content">
                  <h4>Leadership to Ensure Adequate Nutrition (LEAN)</h4>
                  <p>
                    <span>Status:</span> Implemented
                  </p>
                  <p>
                    <span>Donor:</span> United Purpose
                  </p>
                  <p>
                    <span>Beneficiaries:</span> 10000 (Boys-3317: Girls-4422:
                    Women-2261)
                  </p>
                  <p>
                    <span>Duration:</span> January 2020 to December 2020
                  </p>
                  <p>
                    <span>Implementing Area:</span> Jurachari Upazila, Rangamati
                    District
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AshroyAngonProfile;
