import React from "react";
import "../../../style/about.css";

const About = () => {
  return (
    <div className="about_section">
      <div className="container">
        <div>
          <h2>Who We Are</h2>
          <div className="content_wrapper">
            <div>
              <p className="first_paragraph">
                <b
                  style={{
                    color: "#0f894e",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                >
                  ASHROY ANGON SOCIETY
                </b>
                is a non-profit and non political non-governmental development
                organization established in 1997 by a group of philanthropic
                personalities hailing from different faculties and professional
                backgrounds with aiming to address issues of extreme poverty,
                low literacy rate, socio-political conflicts, and cultural
                identity and human rights violations among the indigenous/ethnic
                peoples residing in Chittagong Hill Tracts (CHT) of Bangladesh.
                Primary focus of the organization was confined within poverty
                reduction programs with the indigenous groups of peoples
                engulfed in extreme poverty. In the course of time, the working
                scope of the organization extended on basic education, WaSH,
                health education, environmental conservation of ecological
                balance &amp; bio-diversity, promoting indigenous culture,
                language &amp; heritage and on the pillar of sustainable
                development.
              </p>
            </div>

            <div>
              <p className="second_paragraph">
                <b
                  style={{
                    color: "#0f894e",
                    cursor: "pointer",
                    marginRight: "5px",
                  }}
                >
                  Title and its meaning:
                </b>
                The words
                <b
                  style={{
                    color: "#0f894e",
                    cursor: "pointer",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  ASHROY ANGON SOCIETY (AAS)
                </b>
                in Bengali refer to “Space for Shelter” in English. True to its
                name, the vision and mission of the organization reflect the
                management and staff commitment in fulfilling its commitment.
                Since the very inception, the AAS has been committed and
                dedicated to the cause of promoting socio-economic conditions of
                the most vulnerable and underprivileged sections of the society
                especially in the Chittagong Hill Tracts (CHT) and Chattagram
                Division. Over the years, the Ashroy Angon Society, through
                getting different communities involved, has been instrumental in
                implementing various programs with diverse activities such as,
                awareness raising, capacity building, income generating
                activities, agriculture for food security, livelihood
                development, women empowerment, indigenous/ ethnic peoples’
                rights, child and adult education, primary health care and small
                entrepreneurship development etc. in partnership collaboration
                with the local, national, and international agencies and the
                government agencies as well.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
